.ubf-picture {
	display: block;

	@include ifSupportCustomProperties() {
		// @include minAspectRatio(var(--w));
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		&::before {
			content: '';
			display: block;
			flex-grow: 0;
			flex-shrink: 1;
			width: 0;
			padding-bottom: calc(100% / (var(--w) / var(--h)));
		}
	}

	&.ubf-preview__picture {
		margin-bottom: $base2;
		position: relative;
	}
	&.ubf-videoreel-item__picture {
		margin-bottom: $base2;
		position: relative;
	}
	&.ubf-mainnews-item__picture {
		margin-bottom: $base2;
		position: relative;
	}


	&.ubf-textBox__picture {
		text-align: center;
	}


	&__caption {
		@include ubf-font(caption);
		background-color: $colorBgBase;
		// fix a rendering bug when some letters appears cut on the right
		padding-right: 1px;
		padding-top: $baseHalf;
		text-align: right;

		.ubf-slide & {
			@include ubf-font(slideCaption);
		}

		//vb:02.09.2021: Bugfix for slideshow immage
		.ubf-section--cover & {
			@include ubf-container();
			background-color: transparent;
			color: $colorBgBase;
			padding-bottom: $baseHalf;
			transform: translateY(-$menuHeight) translateY(-100%);
			z-index: 10;
			position: absolute!important;
			top: 100%;
			left: 0;
			right: 0;
			@include media($largeLayoutBreakpoint) {
				padding-bottom: $base;
			}
		}

		.ubf-body--miniCover .ubf-section--cover &,
		.ubf-body--microCover .ubf-section--cover & {
			padding-bottom: $base2;
		}

		.ubf-preview__picture & {
			height: $base2;
			left: 0;
			margin: 0;
			position: absolute;
			top: 100%;
			width: 100%;
			color: $colorBase;
		}

		.ubf-mainnews-item__picture & {
			height: $base2;
			left: 0;
			margin: 0;
			position: absolute;
			top: 100%;
			width: 100%;
			color: $colorBase;
		}
		&--mainnews-item{
			padding-right: $base;
			opacity: .85;
			margin-top:-20px!important;
		}
	}


	&__img {
		max-width: 100%;

		.ubf-html:not(.no-js) &:not(.ubf-js-loaded) {
			height: 1px;
			visibility: hidden;
			opacity: 0;
		}
	  .ubf-html:not(.no-js) .ubf-js-loaded &:not(.ubf-js-loaded) {
		  height: initial;
		  visibility: visible;
		  opacity: 1;
		}


		.ubf-address & {
			@include objectFit(cover);
			height: 100%;
			width: 100%;
		}

		.ubf-section--highlights & {
			@include objectFit(cover);
			height: 100%;
			width: 100%;
		}

		.ubf-highlightLinks & {
			@include objectFit(cover);
			height: 100%;
			width: 100%;
		}

		.ubf-preview & {
			width: 100%;
		}
		.ubf-videoreel-item & {
			width: 100%;
			@include objectFit(cover);
			left: 0;
			height: 100%;
			position: absolute;
			top: 0;
		}

		.ubf-preview:not(.ubf-preview--noCrop) & {
			@include objectFit(cover);
			left: 0;
			height: 100%;
			position: absolute;
			top: 0;
		}

		.ubf-mainnews-item:not(.ubf-preview--noCrop) & {
			@include objectFit(cover);
			left: 0;
			height: 100%;
			position: absolute;
			top: 0;
		}

		.ubf-section--cover & {
			@include objectFit(cover, 50% 50%);
			height: 100%;
			width: 100%;
		}

		.ubf-galleryItem & {
			@include media($largeLayoutBreakpoint) {
				@include objectFit(cover, 50% 50%);
				height: 100%;
				width: 100%;
			}
		}

		.ubf-slideshow & {
			@include objectFit(contain, 50% 0%);
			height: 100%;
			width: 100%;

			.ubf-section--cover & {
				pointer-events: none;
				@include objectFit(cover, 50% 50%);
			}

			.ubf-modal & {
				@include objectFit(contain, 50% 50%);
			}
		}

		.ubf-section--cover .ubf-textBox__picture & {
			object-fit: scale-down;
			height: auto;
			max-height: $base * 10;
			width: 100%;

			@include media($largeLayoutBreakpoint) {
				// it's the maximum value before increasing the box height
				max-height: $base * 13;
			}
		}

		.ubf-footer__picture & {
			width: auto;
			height: 100%;
		}
	}
}


//hide image when no javascript to avoid spacing conflict with noscript version
.ubf-html.no-js noscript + img {
	display: none;
}
