@mixin ubf-coverTextBox() {
	@include minAspectRatio(1);
	margin-left: staticColumnPush(3, 6, $gridGutterSmall);
	width: staticColumnSize(3, 6, $gridGutterSmall);

	@include media('landscape') {
		margin-left: staticColumnPush(8, 12, $gridGutterSmall);
		width: staticColumnSize(4, 12, $gridGutterSmall);
	}

	@include media($largeLayoutBreakpoint) {
		margin-left: staticColumnPush(8, 12, $gridGutterLarge);
		width: staticColumnSize(4, 12, $gridGutterLarge);
	}
}
