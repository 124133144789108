.ubf-text {
	.ubf-sidebar & {
		@include ubf-highlightColor(border-bottom-color);
		border-bottom-style: solid;
		border-bottom-width: $boxBorderSize;
		padding-bottom: $base2;
	}

	.ubf-contentBlock--textBoxed & {
		background-color: $colorHighlightGeneric;
		padding: $gridGutterSmall;

		@include media($largeLayoutBreakpoint) {
			padding: $gridGutterLarge;
		}
	}

	.ubf-contentBlock--textBoxedBorder & {
		border: $colorAlertBorder solid $boxBorderSize;
		padding: $gridGutterSmall;

		@include media($largeLayoutBreakpoint) {
			padding: $gridGutterLarge;
		}
	}
	&--srOnly {
		@include visuallyHidden();
	}
}
