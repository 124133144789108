// Wrap your code that uses custom properties here inside this block
// Pass false to negate the condition
@mixin ifSupportCustomProperties($requireSupport: true) {
	@if ($requireSupport) {
		@supports (--var: 0) {
			@content;
		}
	} @else {
		@supports not (--var: 0) {
			@content;
		}
	}
}
