.ubf-body {
	@include ubf-font(base);
	color: $colorBase;
	font-family: $fontFamilyMain;
	hyphens: auto;
	height: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-top: calc(#{$paddingTopDefaultSmall} + #{$base2});
	position: relative;

	@include media('landscape') {
		padding-top: calc(#{$paddingTopHomeLarge} + #{$base2});
	}

	@include media('landscape', $largeLayoutBreakpoint) {
		padding-top: calc(#{$paddingTopDefaultLarge} - #{$menuHeight});
	}

	&--home {
		@include media('landscape') {
			padding-top: calc(#{$paddingTopHomeLarge} + #{$base2});
		}

		@include media('landscape', $largeLayoutBreakpoint) {
			padding-top: calc(#{$paddingTopHomeLarge} - #{$menuHeight});
		}

		html.ubf-js-mobile & {
			@include media('landscape', $largeLayoutBreakpoint) {
				padding-top: calc(#{$paddingTopHomeLargeMobile} - #{$menuHeight});
			}
		}
	}

	&--ekvv {
		background-color: $colorEkvvBgBase;
		padding-top: $headerHeightSmall + $base2;

		@include media('landscape') {
			padding-top: 0;
		}

		@include media('landscape', $largeLayoutBreakpoint) {
			padding-top: 0;
		}

		@include media($largeLayoutBreakpoint) {
			padding-top: $menuHeight + $base2;
		}
	}

	&--noCover {
		padding-top: $paddingTopNoCoverSmall;

		@include media($largeLayoutBreakpoint) {
			padding-top: $paddingTopNoCoverLarge;
		}
	}


	&--miniCover {
		padding-top: $paddingTopMiniCoverSmall;

		@include media('landscape') {
			padding-top: $paddingTopMiniCoverSmallLandscape;
		}

		@include media($largeLayoutBreakpoint) {
			padding-top: $paddingTopMiniCover;
		}
	}

	&--microCover {
		padding-top: $paddingTopMicroCoverSmall;

		@include media('landscape') {
			padding-top: $paddingTopMicroCoverSmallLandscape;
		}

		@include media($largeLayoutBreakpoint) {
			padding-top: $paddingTopMicroCover;
		}
	}

	&--noCover::before {
		content: '';
		background-color: $colorHighlightGeneric;
		height: $paddingTopNoCoverLarge;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;
	}

	// picture[data-ubf-component="AsyncPicture"] img {
	// 	@include ease(opacity, $duration3);
	// 	opacity: 0;
	// }

	// picture.ubf-js-loaded[data-ubf-component="AsyncPicture"] img {
	// 	opacity: 1;
	// }

	&__alert {
		height: 0;
		position: relative;
		z-index: 100;
	}



	&__mainMenu {
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 5;
		height: 0;

		&:focus-within {
			html.no-js & {
				height: auto;
			}
		}

		&.ubf-js-beforeActive {
			height: auto;
		}

		@include media($xlargeLayoutBreakpoint) {
			height: $menuHeight;
			left: 0;
			// two position values, not an error: the first value is for IE that does not support 'sticky'
			position: relative;
			position: sticky;
			top: 0;
			margin-bottom: $base2;
		}


		.ubf-body--ekvv & {
			@include media($xlargeLayoutBreakpoint) {
				position: fixed;
			}
		}
	}


	&__modal {
		// height: 0;
		// position: relative;
		z-index: 100;
	}
}
