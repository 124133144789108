.ubf-section {
	background-color: $colorBgBase;
	padding-bottom: $sectionVerticalSpaceSmall;

	@include media($largeLayoutBreakpoint) {
		padding-bottom: $sectionVerticalSpaceLarge;
	}

	&--cover {
		padding-bottom: 0;
	}

	.ubf-body--ekvv & {
		background-color: transparent;
		padding-bottom: $sectionVerticalSpaceSmall + $base2;

		@include media($largeLayoutBreakpoint) {
			padding-bottom: $sectionVerticalSpaceLarge + $base2;
		}
	}


	&:not(&--cover)::before {
		background-color: $colorBgBase;
		bottom: 0;
		content: '';
		left: -$base;
		position: absolute;
		right: -$base;
		top: 0;
		z-index: -1;


		.ubf-body--ekvv & {
			background-color: transparent;
		}
	}


	&__bg {
		@include ubf-coverImg();
		&:has(.ubf-section__colorOverlay--green, .ubf-section__colorOverlay--grey) {
			z-index: 1;
			&::after {
				background-image: none;
			}
		}
	}


	&__content {
		&--disturber {
			border: 2px solid #14f5b4;
    		display: flex;
    		align-items: center;
    		height: 6rem;
    		margin-left: 0px;
    		padding-left: 32px;
		}

		@include dynamicColumns($hSpace: $gridGutterSmall, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
		position: relative;
		z-index: 2;

		.ubf-section__title + & {
			margin-top: $base * 1.5;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base3;
			}
		}

		@include media($largeLayoutBreakpoint) {
			// @include dynamicColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
			@include floatColumns($hSpace: $gridGutterLarge, $vSpace: $gridGutterLarge, $hSpacePos: left, $vSpacePos: bottom);
		}

		.ubf-section--cover & {
			@include ubf-container();
			@include staticColumns($vSpace: 0, $vSpacePos: bottom);
			align-items: flex-end;
			height: 100%;
			padding-bottom: $base3;

			@include media($largeLayoutBreakpoint) {
				@include staticColumns($vSpace: 0, $vSpacePos: bottom);
				padding-bottom: $base5;
			}
		}
	}


	&__partnerLogo {
		@include ubf-container();
		bottom: $base3;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		left: 50%;
		pointer-events: none;
		position: absolute;
		transform: translateX(-50%);
		z-index: 100;

		@include media($largeLayoutBreakpoint) {
			bottom: $base5;
		}

		.ubf-contentBlock--slideshow + & {
			bottom: $base * 7.5;

			@include media($largeLayoutBreakpoint) {
				bottom: $base * 11;
			}
		}
	}


	&__partnerLogoPicture {
		width: staticColumnSize(1, 6, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			width: staticColumnSize(2, 12, $gridGutterLarge);
		}
	}


	&__picture {
		height: 100%;
		width: 100%;
	}


	&__separator {
		@include ubf-highlightColor(background-color);
		border: 0;
		height: $boxBorderSize;
		margin-bottom: $base4;
		margin-top: $base4;
		order: 1;
		width: dynamicColumnSize(1, 1, $gridGutterSmall);

		@include media($largeLayoutBreakpoint) {
			width: dynamicColumnSize(1, 1, $gridGutterLarge);
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}


	&__title {
		@include ubf-font(h2);
		position: relative;

		.ubf-body--home &, .ubf-body--portal & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h1);
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				max-width: ubf-ekvvMaxSize(5, 9);
			}
		}
	}

	&__colorOverlay {
		display: none;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;

		&--none {
			display: none;
		}
		&--green {
			display: block;
			background-image: -webkit-linear-gradient(-45deg, $colorHighlightBase 33%, #0000 33%);
		}
		&--grey {
			display: block;
			background-image: -webkit-linear-gradient(-45deg, $colorGreyOverlay 33%, #0000 33%);
		}
		&--highlightcolor {
			display: block;
			@include ubf-highlightColorOverlay();
		}
	}

}
