.ubf-slideshow {
	position: relative;
	// touch-action: pan-y;
	// pointer-events: none;

	.ubf-modal & {
		display: none;
		padding: $base4 0;

		&.ubf-js-active {
			display: block;
			height: 100%;
		}
	}


	&__navigation {
		margin-top: $base2;

		.ubf-section--cover & {
			@include ubf-container();
			bottom: 0;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			z-index: 102;
		}

		.ubf-modal & {
			margin: 0;
			position: absolute;
			left: -$base3;
			right: -$base3;
			top: 50%;
		}
	}


	&__slide {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.ubf-modal & {
			padding-bottom: $base4;
		}
	}


	&__slides {
		@include aspectRatio($gallerySlidesRatio);
		position: relative;
		margin-bottom: $base3;
		z-index: 0;

		.ubf-section--cover &,
		.ubf-modal & {
			height: 100%;
			margin-bottom: 0;

			&::before {
				display: none;
			}
		}
	}
}
