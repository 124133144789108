.ubf-textBox {
	@include ubf-highlightColor(background-color);
	position: relative;

	.ubf-body--generic & {
		background-color: $colorHighlightGeneric;
	}

	.ubf-sidebar & {
		@include media($largeLayoutBreakpoint) {
			@include ubf-highlightColor(border-bottom-color);
			background-color: transparent;
			border-bottom-width: $boxBorderSize;
			border-bottom-style: solid;
			padding-bottom: $base2;
		}
	}

	&--general {
		background-color: $colorBgSubMenu!important;
	}


	&__body {
		flex-grow: 0;

		* + & {
			margin-top: $base2;
		}
	}


	&__content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-height: 100%;
		padding: $base;

		@include media($largeLayoutBreakpoint) {
			padding: $base2;
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				padding: 0;
			}
		}
	}


	&__text {
		flex-grow: 0;

		@include ubf-invertedColorFaculties();
	}


	&__textBody {
		.ubf-section--cover & {
			@include visuallyHidden();

			@include media($largeLayoutBreakpoint) {
				@include visuallyHidden(false);
			}
		}
	}


	&__title {
		@include ubf-font(h2);
		@include ubf-invertedColorFaculties();
		flex-grow: 1;

		.ubf-body--home .ubf-section--highlights & {
			@include ubf-font(h1);
		}


		.ubf-section--cover & {
			@include ubf-font(h);

			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h2);
			}
		}

		.ubf-sidebar & {
			@include media($largeLayoutBreakpoint) {
				@include ubf-font(h3);
			}
		}
	}
}
