$htmlFontSize: 62.5%;

$fontSizes: (
	base: (
		font-size: pxToRem(16),
		line-height: 1.6,
		font-weight: normal
	),
	h1: (
		font-size: pxToRem(55),
		line-height: 1.2,
		font-weight: bold,
		font-style: normal
	),
	h2: (
		font-size: pxToRem(35),
		line-height: 1.3,
		font-weight: bold,
		font-style: normal
	),
	h3: (
		font-size: pxToRem(24),
		line-height: 1.3,
		font-weight: bold,
		font-style: normal
	),
	h4: (
		font-size: pxToRem(20),
		line-height: 1.3,
		font-weight: bold,
		font-style: normal
	),
	h: (
		font-size: pxToRem(16),
		line-height: 1.6,
		font-weight: bold
	),
	small: (
		font-size: pxToRem(11),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	caption: (
		font-size: pxToRem(10),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	slideCaption: (
		font-size: pxToRem(13),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	titleAction: (
		font-size: pxToRem(10),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	weekEventInfo: (
		font-size: pxToRem(11),
		line-height: 1.1,
		font-weight: normal,
		font-style: normal
	),
	helpText: (
		font-size: pxToRem(10),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	date: (
		font-size: pxToRem(10),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	subNav: (
		font-size: pxToRem(16),
		line-height: 1.2,
		font-weight: normal
	),
	footer: (
		font-size: pxToRem(14),
		line-height: 1.2,
		font-weight: normal
	),
	fieldDescription: (
		font-size: pxToRem(10),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	fieldError: (
		font-size: pxToRem(10),
		line-height: 1.3,
		font-weight: normal,
		font-style: normal
	),
	facetValue: (
		font-size: pxToRem(14),
		line-height: 1.25,
		font-weight: normal,
		font-style: normal
	)
);
