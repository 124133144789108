.ubf-sectionNavi {
	position: fixed;
	top: 45%;
	right: 0;
	flex-direction: column;
	justify-content: flex-end;
	z-index: 100;
	display: none;
	@include media('>=ibrid') {
		// display on bigger screens
		display: flex;
	}
	@media (max-height: 400px) {
		// except on very small heights
		display: none;
	}
	&:hover,
	&.ubf-js-scrolling {
		@include media($sectionNaviVisible) {
			.ubf-sectionNavi__item {
				background-color: rgba(255, 255, 255, 0.5);
			}

			.ubf-sectionNavi__label {
				opacity: 0.5;
			}
		}

	}


	&:not(.ubf-js-visible) {
		// visibility: hidden;
	}

	&__item {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		text-decoration: none;
		color: black;
		@include ubf-font(base);
		padding: $base*0.5 $base2 $base*0.5 $base;
		transition: background-color 400ms;

		.ubf-js-scrolling &.ubf-js-current {
			@include media($sectionNaviVisible) {
				background-color: rgba(255, 255, 255, 1) !important;

				.ubf-sectionNavi__label {
					opacity: 1;
					width: initial;
					padding: 0 $base 0 $base;
				}
			}
		}

		.ubf-sectionNavi__icon:hover ~ .ubf-sectionNavi__label {
			opacity: 1;
			width: initial;
			padding: 0 $base 0 $base;
		}

	}

	&__label {
		order:1;
		transition: opacity 400ms;
		padding: 0 0 0 0;
		margin-right: $base;
		opacity: 0;
		pointer-events: none;
		width: 0;
		overflow: hidden;
		text-decoration: none!important;
		color: $colorBase;
		background-color: $colorBgBase;
		font-weight:normal!important;
		white-space: nowrap;
		@include media($sectionNaviVisible) {
			pointer-events: initial;
			background-color: transparent;
			padding: 0 $base 0 0;
			margin-right: 0;
			width: initial;
		}
	}

	&__icon {
		order:2;
		margin-top: $base*0.5;
		@include ease(background-color, $duration2);
		@include ubf-highlightColor(color);
		border: $boxBorderSize solid currentColor;
		display: inline-block;
		height: $galleryNavigationIconSize;
		position: relative;
		width: $galleryNavigationIconSize;
		&:hover {
			@include ubf-highlightColor(color);
		}
		.ubf-body:not(.ubf-body--faculty) & {
			color: $colorHighlightBase;
		}

		.ubf-sectionNavi__item.ubf-js-current & {
			background-color: currentColor;
		}
	}


}
