.ubf-secondaryMenu {
	background-color: $colorBgBase;

	.ubf-body--ekvv &::before {
		@include media($largeLayoutBreakpoint) {
			border-top: 1px solid $colorEkvvBgBase;
			content: '';
			height: 0;
			position: absolute;
			right: 0;
			top: -$base2;
			width: 100%;
		}
	}


	.ubf-body--ekvv &::after {
		@include media($largeLayoutBreakpoint) {
			background-color: $colorBgBase;
			bottom: 0;
			content: '';
			position: absolute;
			right: 0;
			top: -$base2;
			width: 100vw;
			z-index: -1;
		}
	}


	&__content {
		left: 0;
		position: sticky;
		top: $menuHeight + $base2;

		@include media($largeLayoutBreakpoint) {
			padding-bottom: $base5;
		}

		html:not(.ms) & {
			@include media($largeLayoutBreakpoint) {
				@include ease(transform, $duration2);
			}
		}

		.ubf-body--ekvv & {
			position: relative;
			top: 0;
		}
	}


	&__item {
		@include ease(height, $duration4);
		overflow: hidden;
		position: relative;

		&--form {
			overflow: visible;
		}

		& + & {
			margin-top: $base2;

			.ubf-secondaryMenu__item & {
				@include media($largeLayoutBreakpoint) {
					margin-top: $base;
				}
			}
		}

		.ubf-secondaryMenu__item--pevzSearch + &,
		.ubf-secondaryMenu__item--ekvvSearch + & {
			margin-top: 0;

			@include media($largeLayoutBreakpoint) {
				margin-top: $base2;
			}
		}
	}


	&__link {
		color: $colorSubNav;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		position: relative;
		text-decoration: none;

		&:not(:hover):focus {
			text-decoration: underline;
			font-weight: bold;
		}

		.ubf-secondaryMenu &--current {
			@include ubf-highlightColor(color);
			font-weight: bold;
		}

		&--section {
			font-weight: bold;
			color: $colorBase;
		}

		.ubf-secondaryMenu__item--withSubMenu > & {
			font-weight: normal;
		}

		.ubf-mainMenu & {
			color: $colorBase;

			&--current {
				@include ubf-highlightColor(color);
			}
		}

		.ubf-mainMenu .ubf-secondaryMenu__item .ubf-secondaryMenu__item & {
			color: $colorSubNav;

			&--current {
				@include ubf-highlightColor(color);
			}
		}

		&:not(&--current) {
			@include onHover {
				color: $colorBase;
			}
		}
	}


	&__linkIcon {
		@include ease(transform, $duration4);
		line-height: 0;
		height: $base;
		margin-right: $base * 0.5;
		margin-top: $base *3 *0.25;
		position: relative;
		text-align: center;
		width: $base;

		html.no-js .ubf-secondaryMenu__item:focus-within &,
		.ubf-secondaryMenu__link.ubf-js-toggled & {
			transform: rotate(90deg);
		}

		[data-type="svg"] {
			height: 100%;
			width: auto;
		}
	}

	&__linkLabel {
		&--outsideLink {
			text-decoration: underline;
			margin-left: $base1 + $baseHalf;
			margin-right: $baseHalf;
			font-weight: bold;
		}
	}


	&__section {
		& + & {
			margin-top: $base5;

			.ubf-mainMenu & {
				margin-top: $base4;
			}

			.ubf-body--ekvv & {
				@include media($largeLayoutBreakpoint) {
					margin-top: 0;
				}
			}
		}

		.ubf-body--ekvv & {
			@include media($largeLayoutBreakpoint) {
				border-bottom: 1px solid $colorEkvvBgBase;
				margin-bottom: $base2;
				padding-bottom: $base2;
				padding-right: $gridGutterLarge;
			}
		}


		&::before {
			@include ubf-highlightColor(border-top-color);
			border-top-width: $boxBorderSize;
			border-top-style: solid;
			content: '';
			display: block;
			height: 0;
			margin-bottom: $base2;
			width: staticColumnSize(2, 3, $gridGutterLarge);

			.ubf-mainMenu &,
			.ubf-body--ekvv & {
				display: none;
			}
		}
	}


	&__subMenu {
		@mixin showSubmenu() {
			opacity: 1;
			position: relative;
			transform: none;
		}

		& & {
			padding-bottom: 0;
		}

		@include ease(opacity, $duration4);
		opacity: 0;
		padding: $base2 0 $base3 $base2;
		position: absolute;


		.ubf-mainMenu & {
			padding-left: $base5;
			padding-bottom: 0;
		}

		&:focus-within {
			outline: none;

			html.no-js & {
				@include showSubmenu();
			}
		}

		&:not(.ubf-js-collapsed) {
			@include showSubmenu();
		}
	}

	&__linkGroup {
		display: flex;
		flex-direction: column;
	}
}
